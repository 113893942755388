// Generated by Framer (f6c392a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jMVWHpp1E", "W3pOtuyVm"];

const variantClassNames = {jMVWHpp1E: "framer-v-1e3nzsq", W3pOtuyVm: "framer-v-1ujyonv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Divider Desktop": "jMVWHpp1E", "Divider Mobile": "W3pOtuyVm"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "jMVWHpp1E", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jMVWHpp1E", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-FdtAB", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1e3nzsq", className)} data-framer-name={"Divider Desktop"} layoutDependency={layoutDependency} layoutId={"jMVWHpp1E"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({W3pOtuyVm: {"data-framer-name": "Divider Mobile"}}, baseVariant, gestureVariant)}><motion.div className={"framer-lxucag"} layoutDependency={layoutDependency} layoutId={"OcLqg9SIR"} style={{backgroundColor: "rgb(204, 204, 204)"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-FdtAB [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FdtAB .framer-6vo6af { display: block; }", ".framer-FdtAB .framer-1e3nzsq { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 120px; justify-content: center; max-width: 1680px; overflow: hidden; padding: 0px 30px 0px 30px; position: relative; width: min-content; }", ".framer-FdtAB .framer-lxucag { flex: none; height: 1px; max-width: 1680px; overflow: hidden; position: relative; width: 1680px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FdtAB .framer-1e3nzsq { gap: 0px; } .framer-FdtAB .framer-1e3nzsq > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-FdtAB .framer-1e3nzsq > :first-child { margin-top: 0px; } .framer-FdtAB .framer-1e3nzsq > :last-child { margin-bottom: 0px; } }", ".framer-FdtAB.framer-v-1ujyonv .framer-1e3nzsq { height: 80px; width: 1680px; }", ".framer-FdtAB.framer-v-1ujyonv .framer-lxucag { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 120
 * @framerIntrinsicWidth 1680
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"],"constraints":[null,"1680px",null,null]},"W3pOtuyVm":{"layout":["fixed","fixed"],"constraints":[null,"1680px",null,null]}}}
 */
const FramerqS_TV69OF: React.ComponentType<Props> = withCSS(Component, css, "framer-FdtAB") as typeof Component;
export default FramerqS_TV69OF;

FramerqS_TV69OF.displayName = "Divider";

FramerqS_TV69OF.defaultProps = {height: 120, width: 1680};

addPropertyControls(FramerqS_TV69OF, {variant: {options: ["jMVWHpp1E", "W3pOtuyVm"], optionTitles: ["Divider Desktop", "Divider Mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerqS_TV69OF, [])